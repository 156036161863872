(function() {
  var PixelAdminApp, SETTINGS_DEFAULTS;

  SETTINGS_DEFAULTS = {
    is_mobile: false,
    resize_delay: 400,
    stored_values_prefix: 'pa_',
    main_menu: {
      accordion: true,
      animation_speed: 250,
      store_state: true,
      store_state_key: 'mmstate',
      disable_animation_on: ['small'],
      dropdown_close_delay: 300,
      detect_active: false,
      detect_active_predicate: function(href, url) {
        return href === url;
      }
    },
    consts: {
      COLORS: ['#71c73e', '#77b7c5', '#d54848', '#6c42e5', '#e8e64e', '#dd56e6', '#ecad3f', '#618b9d', '#b68b68', '#36a766', '#3156be', '#00b3ff', '#646464', '#a946e8', '#9d9d9d']
    }
  };


  /*
   * @class PixelAdminApp
   */

  PixelAdminApp = function() {
    this.init = [];
    this.plugins = {};
    this.settings = {};
    this.localStorageSupported = typeof window.Storage !== "undefined" ? true : false;
    return this;
  };


  /*
   * Start application. Method takes an array of initializers and a settings object(that overrides default settings).
   * 
   * @param  {Array} suffix
   * @param  {Object} settings
   * @return this
   */

  PixelAdminApp.prototype.start = function(init, settings) {
    if (init == null) {
      init = [];
    }
    if (settings == null) {
      settings = {};
    }
    window.onload = (function(_this) {
      return function() {
        var initilizer, _i, _len, _ref;
        $('html').addClass('pxajs');
        if (init.length > 0) {
          $.merge(_this.init, init);
        }
        _this.settings = $.extend(true, {}, SETTINGS_DEFAULTS, settings || {});
        _this.settings.is_mobile = /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase());
        _ref = _this.init;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          initilizer = _ref[_i];
          $.proxy(initilizer, _this)();
        }
        $(window).trigger("pa.loaded");
        return $(window).resize();
      };
    })(this);
    return this;
  };


  /*
   * Add initializer to the stack.
   * 
   * @param  {Function} callback
   */

  PixelAdminApp.prototype.addInitializer = function(callback) {
    return this.init.push(callback);
  };


  /*
   * Initialize plugin and add it to the plugins list.
   * 
   * @param  {String} plugin_name
   * @param  {Instance} plugin
   */

  PixelAdminApp.prototype.initPlugin = function(plugin_name, plugin) {
    this.plugins[plugin_name] = plugin;
    if (plugin.init) {
      return plugin.init();
    }
  };


  /*
   * Save value in the localStorage/Cookies.
   * 
   * @param  {String}  key
   * @param  {String}  value
   * @param  {Boolean} use_cookies
   */

  PixelAdminApp.prototype.storeValue = function(key, value, use_cookies) {
    var e;
    if (use_cookies == null) {
      use_cookies = false;
    }
    if (this.localStorageSupported && !use_cookies) {
      try {
        window.localStorage.setItem(this.settings.stored_values_prefix + key, value);
        return;
      } catch (_error) {
        e = _error;
        1;
      }
    }
    return document.cookie = this.settings.stored_values_prefix + key + '=' + escape(value);
  };


  /*
   * Save key/value pairs in the localStorage/Cookies.
   * 
   * @param  {Object} pairs
   * @param  {Boolean} use_cookies
   */

  PixelAdminApp.prototype.storeValues = function(pairs, use_cookies) {
    var e, key, value, _results;
    if (use_cookies == null) {
      use_cookies = false;
    }
    if (this.localStorageSupported && !use_cookies) {
      try {
        for (key in pairs) {
          value = pairs[key];
          window.localStorage.setItem(this.settings.stored_values_prefix + key, value);
        }
        return;
      } catch (_error) {
        e = _error;
        1;
      }
    }
    _results = [];
    for (key in pairs) {
      value = pairs[key];
      _results.push(document.cookie = this.settings.stored_values_prefix + key + '=' + escape(value));
    }
    return _results;
  };


  /*
   * Get value from the localStorage/Cookies.
   * 
   * @param  {String} key
   * @param  {Boolean} use_cookies
   */

  PixelAdminApp.prototype.getStoredValue = function(key, use_cookies, deflt) {
    var cookie, cookies, e, k, pos, r, v, _i, _len;
    if (use_cookies == null) {
      use_cookies = false;
    }
    if (deflt == null) {
      deflt = null;
    }
    if (this.localStorageSupported && !use_cookies) {
      try {
        r = window.localStorage.getItem(this.settings.stored_values_prefix + key);
        return (r ? r : deflt);
      } catch (_error) {
        e = _error;
        1;
      }
    }
    cookies = document.cookie.split(';');
    for (_i = 0, _len = cookies.length; _i < _len; _i++) {
      cookie = cookies[_i];
      pos = cookie.indexOf('=');
      k = cookie.substr(0, pos).replace(/^\s+|\s+$/g, '');
      v = cookie.substr(pos + 1).replace(/^\s+|\s+$/g, '');
      if (k === (this.settings.stored_values_prefix + key)) {
        return v;
      }
    }
    return deflt;
  };


  /*
   * Get values from the localStorage/Cookies.
   * 
   * @param  {Array} keys
   * @param  {Boolean} use_cookies
   */

  PixelAdminApp.prototype.getStoredValues = function(keys, use_cookies, deflt) {
    var cookie, cookies, e, k, key, pos, r, result, v, _i, _j, _k, _len, _len1, _len2;
    if (use_cookies == null) {
      use_cookies = false;
    }
    if (deflt == null) {
      deflt = null;
    }
    result = {};
    for (_i = 0, _len = keys.length; _i < _len; _i++) {
      key = keys[_i];
      result[key] = deflt;
    }
    if (this.localStorageSupported && !use_cookies) {
      try {
        for (_j = 0, _len1 = keys.length; _j < _len1; _j++) {
          key = keys[_j];
          r = window.localStorage.getItem(this.settings.stored_values_prefix + key);
          if (r) {
            result[key] = r;
          }
        }
        return result;
      } catch (_error) {
        e = _error;
        1;
      }
    }
    cookies = document.cookie.split(';');
    for (_k = 0, _len2 = cookies.length; _k < _len2; _k++) {
      cookie = cookies[_k];
      pos = cookie.indexOf('=');
      k = cookie.substr(0, pos).replace(/^\s+|\s+$/g, '');
      v = cookie.substr(pos + 1).replace(/^\s+|\s+$/g, '');
      if (k === (this.settings.stored_values_prefix + key)) {
        result[key] = v;
      }
    }
    return result;
  };

  PixelAdminApp.Constructor = PixelAdminApp;

  window.PixelAdmin = new PixelAdminApp;

}).call(this);
